
























import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  components: {
    ALogo: () => import("@/components/atoms/a-logo.vue"),
    OSidebar: () => import("@/components/organisms/o-sidebar.vue"),
    MGlobalSearch: () => import("@/components/molecules/m-global-search.vue"),
    MAlerts: () => import("@/components/molecules/m-alerts.vue"),
  },

  setup(_, { root }) {
    const state = reactive({
      navigation: null,
    });

    const hasAccessTo = (permission: string) =>
      root.$store.getters["user/hasAccessTo"](permission);

    return { state, hasAccessTo };
  },
});
